import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  IconButton,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Paper,
  Checkbox,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import CreateDialog from './CreateDialog';
import DeleteDialog from './DeleteDialog';
import UpdateDialog from './UpdateDialog';
import StatusAlert from './StatusAlert';
import ButtonGroup from '@mui/material/ButtonGroup';
import { format } from 'date-fns';
import { usePromiseTracker } from 'react-promise-tracker';
import Loader from 'react-loader-spinner';
import RefreshIcon from '@mui/icons-material/Refresh';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'id',
    label: 'ID',
  },
  {
    id: 'domain',
    label: 'Domain Name',
  },
  {
    id: 'origin',
    label: 'Origin',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'last_modified',
    label: 'Last Modified',
  },
  {
    id: 'notes',
    label: 'Note'
  }
];

function EnhancedTableHead({ order, orderBy, onRequestSort }) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'center'}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const {
    numSelected,
    distributionId,
    getDistributionData,
    setResult,
    filter,
    setFilter,
    setDistributions,
    distributions,
  } = props;

  const inputRef = useRef(null); //What is this?

  const handleFilterChange = useCallback(
    (event) => {
      setFilter(event.target.value);
    },
    [setFilter]
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [filter]);

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <TextField
        label='Search by ID'
        variant='outlined'
        size='small'
        value={filter}
        onChange={handleFilterChange}
        inputRef={inputRef}
        sx={{ width: '25ch' }}
      />
      <ButtonGroup>
        <IconButton
          color='primary'
          aria-label='Refresh'
          component='span'
          onClick={getDistributionData}
          sx={{ mr: 2 }}
        >
          <RefreshIcon />
        </IconButton>
        <CreateDialog
          getDistributionData={getDistributionData}
          setResult={setResult}
          setDistributions={setDistributions}
          distributions={distributions}
        />
        {numSelected > 0 && (
          <DeleteDialog
            distributionId={distributionId}
            numSelected={numSelected}
            onSubmitCallback={getDistributionData}
            childToParent={setResult}
          />
        )}
      </ButtonGroup>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  distributionId: PropTypes.string,
  isItemSelected: PropTypes.bool,
  getDistributionData: PropTypes.func,
  setResult: PropTypes.func,
  showDisabled: PropTypes.bool.isRequired,
  setShowDisabled: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
  setFilter: PropTypes.func.isRequired,
};

export default function DistributionsTable(props) {
  const { distributions, getDistributionData, setDistributions } = props;
  const [order, setOrder] = useState('desc');
  const [displayDistributions, setDisplayDistributions] = useState([]);
  const [orderBy, setOrderBy] = useState('last_modified');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filter, setFilter] = useState('');
  const [showDisabled, setShowDisabled] = useState(false);
  const [result, setResult] = useState({
    statusCode: null,
    body: null,
    url: null,
  });

  useEffect(() => {
    if (showDisabled && filter === '') {
      setDisplayDistributions(distributions);
    }
    if (!showDisabled && filter === '') {
      let active = distributions.filter((dist) => dist.status === 'true');
      setDisplayDistributions(active);
    }
    if (filter !== '') {
      let filteredRows = distributions.filter((row) =>
        row.id.toLowerCase().includes(filter.toLowerCase())
      );
      setDisplayDistributions(filteredRows);
    }
  }, [distributions, showDisabled, filter]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleCheck = (id) => {
    selected.indexOf(id) > -1 ? setSelected([]) : setSelected([id]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - displayDistributions.length)
      : 0;

  const LoadingExistingTable = ({ showDisabled }) => {
    const { promiseInProgress } = usePromiseTracker();
    return promiseInProgress ? (
      <div
        style={{
          width: '100%',
          height: '100',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Loader
          type='ThreeDots'
          color='rgb(255, 255, 255, 0.2)'
          height='100'
          width='100'
        />
      </div>
    ) : (
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          distributionId={selected[0]}
          getDistributionData={getDistributionData}
          distributions={distributions}
          setDistributions={setDistributions}
          setResult={setResult}
          showDisabled={showDisabled}
          setShowDisabled={setShowDisabled}
          filter={filter}
          setFilter={setFilter}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby='tableTitle'
            size={'small'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
               rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(displayDistributions, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const distributionLink = `https://${row.domain}`;

                  // if (!showDisabled && row.status === "false") {
                  //   return null;
                  // }

                  return (
                    <TableRow
                      hover
                      role='checkbox'
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell
                        padding='checkbox'
                        align='center'
                        onClick={() => handleCheck(row.id)}
                      >
                        <Checkbox
                          color='primary'
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell
                        component='th'
                        id={labelId}
                        scope='row'
                        padding='none'
                        align='center'
                        onClick={() => handleCheck(row.id)}
                      >
                        {row.id.startsWith('RPAS')
                          ? row.id.substring(4)
                          : row.id.startsWith('NE')
                          ? 'NE'
                          : row.id}
                      </TableCell>
                      <TableCell
                        align='center'
                        onClick={() => handleCheck(row.id)}
                      >
                        <a
                          href={distributionLink}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {row.domain}
                        </a>
                      </TableCell>
                      <TableCell
                        align='center'
                        onClick={() => handleCheck(row.id)}
                      >
                        {row.origin}
                      </TableCell>
                      <TableCell align='center'>
                        <UpdateDialog
                          stackName={row.id}
                          distributionId={row.name}
                          status={row.status}
                          onSubmitCallback={getDistributionData}
                          childToParent={setResult}
                        />
                      </TableCell>
                      <TableCell
                        align='center'
                        onClick={() => handleCheck(row.id)}
                      >
                        {row.last_modified
                          ? format(
                              Date.parse(row.last_modified),
                              'MMMM dd, yyyy hh:mm a'
                            )
                          : format(
                              Date.parse(row.creation),
                              'MMMM dd, yyyy hh:mm a'
                            )}
                      </TableCell>
                      <TableCell
                        align='center'
                      >
                        {row.note !== row.origin ? row.note : ''}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          padding={2}
        >
          <FormControlLabel
            control={
              <Switch
                checked={showDisabled}
                onChange={() => setShowDisabled(!showDisabled)}
              />
            }
            label='Show Disabled'
          />
          <TablePagination
            rowsPerPageOptions={[10, 15, 20]}
            component='div'
            count={displayDistributions.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Paper>
    );
  };

  return (
    <Box sx={{ width: '100%' }}>
      {result.statusCode !== null ? (
        <StatusAlert result={result} setResult={setResult} />
      ) : (
        ''
      )}
      <LoadingExistingTable showDisabled={showDisabled} />
    </Box>
  );
}
